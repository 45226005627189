<template>
  <div class="aboutBox">
    <mobileHeader />
    <div class="bg">
      <img src="../../assets/m_aboutUs.jpg" />
      <div
        class="banner-title"
        :class="{ bannerTitleB: $i18n.locale === 'en-US' }"
      >
        {{ $t("lang.home.detail.title") }}
      </div>
    </div>
    <div class="aboutContainer">
      <div class="parp">
        {{ $t("lang.home.detail.subTitle") }}
      </div>
      <div class="titles">{{ $t("lang.aboutUs.titles") }}</div>
      <div class="detail">
        {{ $t("lang.home.detail.container") }}
      </div>
      <div class="titles">{{ $t("lang.aboutUs.title") }}</div>
      <div class="details">
        <div class="part">
          <span>• {{ $t("lang.aboutUs.itemOne.title") }}</span>
          <div class="symbol">{{ $t("lang.aboutUs.itemOne.desc") }}</div>
        </div>
        <div class="part">
          <span>• {{ $t("lang.aboutUs.itemTwo.title") }}</span>
          <div class="symbol">{{ $t("lang.aboutUs.itemTwo.desc") }}</div>
        </div>
        <div class="part">
          <span>• {{ $t("lang.aboutUs.itemThree.title") }}</span>
          <div class="symbol">{{ $t("lang.aboutUs.itemThree.desc") }}</div>
        </div>
        <div class="part">
          <span>• {{ $t("lang.aboutUs.itemFour.title") }}</span>
          <div class="symbol">{{ $t("lang.aboutUs.itemFour.desc") }}</div>
        </div>
      </div>
    </div>
    <mobileFooter />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
export default {
  name: "aboutUs",
  components: {
    mobileHeader,
    mobileFooter,
  },
};
</script>

<style scoped lang="scss">
.aboutBox {
  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .banner-title {
      position: absolute;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }
    .bannerTitleB {
      font-family: "English-Bold", serif;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
  .aboutContainer {
    padding: 0 25px 40px;
    .parp {
      margin: 50px 0 40px 0;
      font-size: 16px;
      font-weight: bold;
      color: #6d7276;
    }
    .titles {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
    }
    .detail {
      font-size: 16px;
      color: #6d7276;
      line-height: 25px;
      margin-bottom: 30px;
    }
    .details {
      .part {
        font-size: 16px;
        color: #6d7276;
        margin-bottom: 15px;
        line-height: 23px;
        .symbol {
          margin-left: 10px;
        }
        span {
          font-weight: bold;
          color: #6d7276;
        }
      }
    }
  }
}
</style>
